<template>
  <div class="mbf-product">
    <KTCodePreview v-bind:title="'Danh sách sản phẩm Mobifone'">
      <template v-slot:toolbar>
        <b-row>
          <div class="mr-2">
            <b-dropdown size="sm" right class="px-0 d-none">
              <template slot="button-content">
                <i class="fas fa-cog" style="font-size: 1rem"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="showModal" title="Nhập file excel">
                <v-icon small class="text-success" v-b-tooltip
                  >mdi-file-export</v-icon
                >
                Nhập từ excel</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <div>
            <b-button
              style="width: 100px"
              variant="primary"
              size="sm"
              @click="showModalCreate()"
              class="font-weight-bolder"
            >
              <v-icon small class="text-white" v-b-tooltip
                >mdi-plus-circle</v-icon
              >
              Tạo mới</b-button
            >
          </div>
        </b-row>
      </template>
      <template v-slot:preview>
        <!-- title -->
        <b-row class="mb-4">
          <b-col cols="12">
            <div class="row d-flex align-items-center">
              <b-col cols="2">
                <b-form-input
                  v-model="apiParams.productId"
                  size="sm"
                  placeholder="Id sản phẩm"
                ></b-form-input>
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="apiParams.searchProduct"
                  size="sm"
                  placeholder="Mã barcode hoặc tên sản phẩm"
                ></b-form-input>
              </b-col>
              <b-col cols="3">
                <div class="d-flex">
                  <b-input-group>
                    <date-picker
                      placeholder="Từ"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="apiParams.fromDate"
                    ></date-picker>
                  </b-input-group>
                  <span class="ml-1 mr-1"></span>
                  <b-input-group>
                    <date-picker
                      placeholder="Đến"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="apiParams.toDate"
                    ></date-picker>
                  </b-input-group>
                </div>
              </b-col>
              <b-col cols="2">
                <b-form-select
                  v-model="apiParams.approve"
                  size="sm"
                  class="select-style"
                  :options="[
                    {
                      id: null,
                      name: 'Lựa chọn trạng thái',
                    },
                    {
                      id: 0,
                      name: 'Chưa duyệt',
                    },
                    {
                      id: 1,
                      name: 'Đã duyệt',
                    },
                  ]"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
              <b-col cols="2">
                <b-form-select
                  v-model="apiParams.calculate"
                  size="sm"
                  class="select-style"
                  :options="[
                    {
                      id: null,
                      name: 'Lựa chọn công thức',
                    },
                    {
                      id: 0,
                      name: 'Cũ',
                    },
                    {
                      id: 1,
                      name: 'Mới',
                    },
                  ]"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="12">
            <b-button
              style="fontweight: 600"
              variant="primary"
              @click="fetchProduct"
              size="sm"
              class="mr-1"
            >
              <v-icon small class="text-white" v-b-tooltip>mdi-filter</v-icon>
              Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <div class="col-6 justify-content-start align-items-center d-flex">
            <div>
              <b class="mr-3"
                >Đã duyệt:
                <span class="text-warning">{{
                  items.filter((x) => parseInt(x.approve) === 1).length
                }}</span></b
              >
              <b class="mr-3"
                >Chưa duyệt:
                <span class="text-success">{{
                  items.filter((x) => parseInt(x.approve) === 0).length
                }}</span></b
              >
              <b class="mr-1"
                >Mới:
                <span class="text-danger">{{
                  items.filter(
                    (x) =>
                      moment(x.createdAt).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                  ).length
                }}</span></b
              >
            </div>
          </div>
          <div class="col-6 justify-content-end d-flex">
            <div>
              <div class="overflow-auto">
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="totalPage"
                  :per-page="perPage"
                  size="sm"
                  align="right"
                  style="border-radius: 5px"
                  class="bg-light p-2"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-row>

        <table
          class="
            table
            table-bordered
            table-vertical-center
            table-hover
            table-font-size
            mb-0
          "
        >
          <thead>
            <tr class="bg-light">
              <th scope="col" class="date-col text-center">Ngày tạo</th>
              <th scope="col" class="id-col text-center">ID</th>
              <th scope="col" class="name-col text-center">Tên sản phẩm</th>
              <th scope="col" class="name-col text-center">Tên sản phẩm Vat</th>
              <th scope="col" class="text-center cal-col">Loại giá</th>
              <th scope="col" class="text-center">Bán tại sis</th>
              <th scope="col" class="text-center">Phân loại</th>
              <th scope="col" class="text-center approve-col">Tình trạng</th>
              <th scope="col" class="text-center">Đẩy lệnh</th>
              <th scope="col" class="text-center"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-center">
                <span style="font-size: 0.9rem">
                  {{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
                </span>
              </td>
              <td v-bind:title="item.productId">
                <a href="javascript:void(0);" @click="showModalUpdate(item.id)"
                  ><b>{{ item.productId }}</b></a
                >
              </td>
              <td v-bind:title="item.productName">
                <b class="d-block"> {{ item.barCode }}</b>
                <span @click="editItem(item)" class="link-style">
                  {{ item.productName }}</span
                >
              </td>
              <td v-bind:title="item.productNameVat">
                {{ item.productNameVat }}
              </td>
              <td class="text-center">
                <b v-if="item.newCalculate === 1" class="text-warning">Mới</b>
                <b v-else-if="item.newCalculate === 0" class="text-dark">Cũ</b>
                <b v-else>Chưa xác định</b>
              </td>
              <td class="text-center">
                <span
                  v-if="item.isSelling === 1"
                  title="Được bán tại SiS"
                  v-b-tooltip
                >
                  <i class="fas fa-check-circle text-success"></i>
                </span>
                <span v-else title="Không được bán tại SiS" v-b-tooltip>
                  <i class="fas fa-times-circle text-danger"></i>
                </span>
              </td>
              <td class="text-center">
                <span
                  v-if="item.productMbfType === 0"
                  title="Loại điện thoại"
                  v-b-tooltip
                >
                  <i class="fas fa-mobile-alt text-dark"></i>
                </span>
                <span
                  v-else-if="item.productMbfType === 1"
                  title="Loại đồng hồ"
                  v-b-tooltip
                >
                  <i class="fas fa-clock text-dark"></i>
                </span>
                <span
                  v-else-if="item.productMbfType === 2"
                  title="Loại phụ kiện"
                  v-b-tooltip
                >
                  <i class="fa fa-headphones text-dark"></i>
                </span>
                <span v-else title="Không xác định" v-b-tooltip>
                  <i class="fas fa-times-circle text-danger"></i>
                </span>
              </td>
              <td v-bind:title="item.approve" class="text-center">
                <span
                  v-if="item.approve === 1"
                  class="badge badge-warning text-white"
                  >Đã duyệt</span
                >
                <span v-else class="badge badge-secondary">Chưa duyệt</span>
              </td>
              <td>
                <span v-if="item.push === 1">
                  <b-badge variant="secondary">Chưa đẩy </b-badge>
                </span>
                <span v-else-if="item.push === 2">
                  <b-badge variant="warning text-white">Đang đẩy </b-badge>
                </span>
                <span v-else-if="item.push === 3">
                  <b-badge variant="success">Đẩy thành công </b-badge>
                </span>
                <span v-else>
                  <b-badge variant="danger">Chưa xác định</b-badge>
                </span>
              </td>
              <td class="text-center">
                <b-dropdown size="sm" no-caret right v-if="isValid(item)">
                  <template v-slot:button-content>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item
                    v-on:click="approveProduct(item.id)"
                    title="Cập nhật trạng thái duyệt"
                  >
                    <div v-if="item.approve !== 1">
                      <v-icon class="text-success mr-1" v-b-tooltip small
                        >mdi-check-circle</v-icon
                      >
                      Duyệt lệnh
                    </div>
                    <div v-else>
                      <v-icon class="text-danger mr-1" v-b-tooltip small
                        >mdi-minus-circle</v-icon
                      >Huỷ Duyệt
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item v-on:click="deleteProduct(item.id)">
                    <v-icon class="text-danger mr-1" v-b-tooltip small
                      >mdi-delete-circle</v-icon
                    >Xoá</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-on:click="pushProduct(item.id)"
                    title="Đẩy lệnh qua mobifone"
                  >
                    <v-icon class="text-warning mr-1" v-b-tooltip small
                      >mdi-play-circle</v-icon
                    >
                    Đẩy lệnh</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-12">
          <div class="row bg-light" style="border-radius: 0px 0px 5px 5px">
            <div class="col-6 h-50 my-auto">
              <b>Tổng sản phẩm: {{ totalPage }}</b>
            </div>
            <div class="col-6">
              <div class="overflow-auto">
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="totalPage"
                  :per-page="perPage"
                  size="sm"
                  align="right"
                  class="p-3"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </KTCodePreview>
    <b-modal ref="my-modal" size="xl" hide-footer title="Import Excel">
      <v-form ref="form">
        <div class="mb-4">
          <label
            >Chọn File:
            <input type="file" id="file" ref="file" v-on:change="getFile()" />
          </label>
        </div>
        <b-table
          hover
          bordered
          small
          responsive
          :items="itemsChild"
          :fields="fieldsChild"
          :per-page="perPageChild"
          :current-page="currentPageChild"
        >
          <template v-slot:cell(isActived)="row">
            <span v-if="row.value === true" class="badge badge-success"
              >Hoạt động</span
            >
            <span v-else class="badge badge-danger">Ngừng hoạt động</span>
          </template>
        </b-table>
        <template>
          <div
            class="text-center col-md-12 text-primary my-2"
            v-show="itemsChild.length <= 0"
          >
            <p>Không có danh mục con</p>
          </div>
        </template>
        <b-pagination
          class="float-right"
          v-model="currentPageChild"
          :total-rows="totalRows"
          :per-page="perPageChild"
          aria-controls="my-table"
        ></b-pagination>
        <b-form-group label="Duyệt lệnh">
          <b-form-radio-group v-model="approveChild" name="radio-sub-component">
            <b-form-radio value="1">Đã duyệt</b-form-radio>
            <b-form-radio value="0">Chưa duyệt</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-button
          style="fontweight: 600; width: 100px"
          variant="warning"
          size="sm"
          @click="createBulkProduct()"
          :disabled="onBusy"
          class="mr-2"
          v-show="itemsChild.length > 0"
          >Duyệt lệnh</b-button
        >
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          class="mr-2"
          @click="uploadFile()"
          :disabled="!file"
          >Tải lên</b-button
        >
        <b-button
          style="font-weight: 600; width: 100px"
          variant="secondary"
          size="sm"
          @click="hideModal()"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
    <b-modal ref="cal-modal" hide-footer title="Cập nhật công thức">
      <v-form ref="form" lazy-validation>
        <div>
          <div v-if="calculate">
            <p>
              Tên sản phẩm: <b>{{ calculate.name }}</b>
            </p>
            <p>
              Barcode: <b>{{ calculate.barcode }}</b>
            </p>
            <p>
              Price: <b>{{ calculate.price }}</b>
            </p>
            <p>
              Duyệt: <b v-if="calculate.approve === 1">Đã duyệt</b>
              <b v-else>Chưa duyệt</b>
            </p>
          </div>
          <b-form-group label="Công thức">
            <b-form-radio-group
              v-model="selectedCal"
              name="radio-sub-component"
            >
              <b-form-radio value="1">Mới</b-form-radio>
              <b-form-radio value="0">Cũ</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <b-button
          style="width: 100px"
          variant="success"
          size="sm"
          v-on:click="changeCalculate()"
          class="mr-3"
        >
          <strong>Cập nhật</strong>
        </b-button>
        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="hideModalCalculate"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <b-modal
      ref="my-modal-create"
      id="my-modal-create"
      size="lg"
      hide-footer
      v-bind:title="upsertProduct"
    >
      <v-form ref="form" lazy-validation>
        <div class="mb-2">
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <b-input-group>
                  <b-form-input
                    v-model="product.productId"
                    required
                    size="sm"
                    :disabled="update"
                    placeholder="Nhập id sp"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="getProductDetail()"
                      :disabled="onBusy"
                    >
                      <i class="fas fa-search"></i> Tìm</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Tên sản phẩm:">
                <b-form-input
                  v-model="product.productName"
                  required
                  size="sm"
                  :disabled="true"
                  placeholder="Nhập tên sản phẩm"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Loại giá:">
                <b-form-select
                  v-model="$v.product.newCalculate.$model"
                  size="sm"
                  :options="[
                    { id: null, name: 'Chọn công thức' },
                    { id: 0, name: 'Cũ' },
                    { id: 1, name: 'Mới' },
                  ]"
                  value-field="id"
                  text-field="name"
                  class="select-style"
                  @change="onchangeFormula"
                  :state="validateItem('newCalculate')"
                >
                </b-form-select>
                <b-form-invalid-feedback>
                  Yêu cầu chọn loại giá.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Bán tại sis:">
                <b-form-select
                  v-model="$v.product.isSelling.$model"
                  size="sm"
                  :options="[
                    { id: null, name: 'Chọn quy định' },
                    { id: 1, name: 'Được bán' },
                    { id: 2, name: 'Chưa được bán' },
                  ]"
                  value-field="id"
                  text-field="name"
                  class="select-style"
                  :state="validateItem('isSelling')"
                >
                </b-form-select>
                <b-form-invalid-feedback>
                  Yêu cầu chọn sản phẩm bán tại sis.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Tên sản phẩm Vat:">
                <b-form-input
                  v-model="$v.product.productNameVat.$model"
                  required
                  size="sm"
                  :disabled="true"
                  placeholder="Nhập tên sản phẩm Vat"
                  :state="validateItem('productNameVat')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  Yêu cầu cập nhật tên vat sản phẩm.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Tình trạng:">
                <b-form-select
                  v-model="$v.product.approve.$model"
                  size="sm"
                  class="select-style"
                  :options="[
                    { id: null, name: 'Chọn trạng thái' },
                    { id: 0, name: 'Chưa duyệt' },
                    { id: 1, name: 'Đã duyệt' },
                  ]"
                  value-field="id"
                  text-field="name"
                  :state="validateItem('approve')"
                >
                </b-form-select>
                <b-form-invalid-feedback>
                  Yêu cầu chọn trình trạng:
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Phân loại:">
                <b-form-select
                  v-model="$v.product.productMbfType.$model"
                  size="sm"
                  :options="[
                    { id: null, name: 'Chọn loại sản phẩm' },
                    { id: 0, name: 'Loại điện thoại' },
                    { id: 1, name: 'Loại đồng hồ' },
                    { id: 2, name: 'Loại phụ kiện' },
                  ]"
                  value-field="id"
                  text-field="name"
                  class="select-style"
                  :state="validateItem('productMbfType')"
                >
                </b-form-select>
                <b-form-invalid-feedback>
                  Yêu cầu chọn loại sản phẩm:
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-form-group label="Barcode:">
                <b-form-input
                  v-model="product.barCode"
                  required
                  size="sm"
                  :disabled="true"
                  placeholder="Nhập barcode"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Giá tiền sản phẩm:">
                <b-form-input
                  v-model="product.productSellingPrice"
                  required
                  size="sm"
                  v-mask.raw="mask"
                  :disabled="true"
                  placeholder="Giá tiền sản phẩm"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Giá tiền theo công thức:">
                <b>{{ convertPrice(product.productSellingPriceByFormula) }}</b>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Đẩy lệnh:">
                <b>
                  <span v-if="product.push === 1"> Chưa đẩy </span>
                  <span v-else-if="product.push === 2"> Đang đẩy </span>
                  <span v-else-if="product.push === 3"> Đẩy thành công </span>
                  <span v-else> Chưa xác định </span>
                </b>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          v-on:click="updateProduct()"
          :disabled="onBusy"
          class="mr-3"
          v-show="update"
          >Cập nhật</b-button
        >
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          v-on:click="createProduct()"
          :disabled="onBusy"
          class="mr-3"
          v-show="create"
          >Tạo mới</b-button
        >
        <b-button
          class="mr-3"
          style="fontweight: 600; width: 100px"
          variant="warning"
          size="sm"
          v-show="create"
          @click="resetProduct"
          >Mặc định</b-button
        >
        <b-button
          style="fontweight: 600; width: 100px"
          variant="secondary"
          size="sm"
          @click="hideModalCreate"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
  </div>
</template>
<style>
.id-col {
  width: 5%;
}
.name-col {
  width: 20%;
}
.cal-col {
  width: 5%;
}
.approve-col {
  width: 10%;
}
.barcode-col {
  width: 10%;
}
.quantity-col {
  width: 3%;
}
.customer-col {
  width: 14%;
}
.status-col {
  width: 10%;
}
.select {
  text-align-last: center;
}
.date-col {
  width: 8%;
}
</style>
<style lang="scss">
.mbf-product {
  .icon-size {
    font-size: 1.4rem;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .link-style {
    cursor: pointer;
  }

  .link-style:hover {
    text-decoration: underline;
  }
}
#my-modal-create {
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import ApiService from '@/core/services/api.service';
import {
  makeToastFaile,
  convertPrice,
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
} from './../../../utils/common';
import DatePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default {
  mixins: [validationMixin],
  validations: {
    product: {
      productNameVat: {
        required,
      },
      newCalculate: {
        required,
      },
      approve: {
        required,
      },
      productMbfType: {
        required,
      },
      isSelling: {
        required,
      },
    },
  },
  data() {
    return {
      mask: currencyMask,
      apiParams: {
        productSearch: null,
        fromDate: null,
        toDate: null,
        approve: null,
        calculate: null,
        productId: null,
      },
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      items: [],
      selectedAll: false,
      onBusy: false,
      isNoData: false,
      file: '',
      fileName: '',
      Toast: '',
      filter: '',
      selectedCal: '',
      calculate: null,
      currentPage: 1,
      totalPage: 0,
      perPage: 20,
      product: {
        id: null,
        productId: '',
        productName: '',
        productNameVat: '',
        productSellingPrice: '',
        barCode: '',
        newCalculate: null,
        approve: null,
        productMbfType: null,
        isSelling: null,
        push: null,
        productSellingPriceByFormula: 0,
      },
      upsertProduct: '',
      create: false,
      update: false,
      itemsChild: [],
      fieldsChild: [
        {
          key: 'productId',
          label: 'Id Nhanh',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'nameNhanh',
          label: 'Tên',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Tên khác',
          sortable: true,
        },
        {
          key: 'barcode',
          label: 'Barcode',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'productSellingPrice',
          label: 'Giá tiền',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'newCalculate',
          label: 'Công thức',
          class: 'text-center',
          sortable: true,
          formatter: (value) => {
            return value ? 'Mới' : 'Cũ';
          },
        },
        {
          key: 'push',
          label: 'Đẩy lệnh',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'valid',
          label: 'Kiểm tra',
          class: 'text-center',
          sortable: true,
          formatter: (value, key, item) => {
            console.log(key, item);
            return value ? (value ? 'Hợp lệ' : 'Không hợp lệ') : '';
          },
        },
      ],
      perPageChild: 10,
      currentPageChild: 1,
      approveChild: 1,
    };
  },
  components: {
    KTCodePreview,
    DatePicker,
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchProduct();
      },
    },
  },
  computed: {
    currentDateDisplay() {
      return moment(new Date()).format('YYYY-MM-DD');
    },
    filteredRows() {
      return this.items.filter((row) => {
        const barcode = row.barcode.toLowerCase();
        const name = row.name.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return barcode.includes(searchTerm) || name.includes(searchTerm);
      });
    },
    totalRows() {
      return this.itemsChild.length;
    },
  },
  created() {
    this.fetchProduct();
    this.Toast = this.showMixinSweetAlert();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/accountants' },
      { title: 'Danh sách sản phẩm Mobifone' },
    ]);
  },
  methods: {
    convertPrice,
    moment,
    validateItem(name) {
      const { $dirty, $error } = this.$v.product[name];
      return $dirty ? !$error : null;
    },
    getFile() {
      this.file = this.$refs.file.files[0];
    },
    highlightMatches(text) {
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, 'ig');
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    uploadFile() {
      this.isNoData = false;
      let formData = new FormData();
      formData.append('file', this.file);
      axios
        .post(process.env.VUE_APP_PRODUCT + '/upload-excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.itemsChild = response.data.data;
            this.Toast.fire({
              icon: 'success',
              title: 'Đọc file thành công',
            });
          }

          // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          // var fileLink = document.createElement("a");
          // fileLink.href = fileURL;
          // let headerLine = response.headers["content-disposition"];
          // let startFileNameIndex = headerLine.indexOf('"') + 1;
          // let endFileNameIndex = headerLine.lastIndexOf('"');
          // let fileName = headerLine.substring(
          //   startFileNameIndex,
          //   endFileNameIndex
          // );
          // fileLink.setAttribute("download", fileName);
          // document.body.appendChild(fileLink);
          // fileLink.click();
        })
        .catch(() => {
          this.Toast.fire({
            icon: 'error',
            title: 'Đọc file thất bại',
          });
        });
    },
    fetchProduct() {
      ApiService.query('mbf/products', {
        params: {
          size: 20,
          page: this.currentPage,
          ...this.apiParams,
        },
      }).then((result) => {
        if (result.data.status) {
          this.items = result.data.data.data;
          this.totalPage = result.data.data.totalPage;
        }
      });
    },
    showModal() {
      this.approveChild = 1;
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    showMixinSweetAlert() {
      return Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    },
    async getFileTmp() {
      let url = process.env.VUE_APP_TMP_FILE_BBBG;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let fileName = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    approveProduct(id) {
      ApiService.put('mbf/products/approve', {
        id,
      })
        .then((result) => {
          if (result.data.status) {
            this.fetchProduct();
            makeToastSuccess(result.data.message);
          } else {
            makeToastFaile(result.data.message);
          }
        })
        .catch((error) => {
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    showModalCalculate(item) {
      this.selectedCal = item.newCalculate ? 1 : 0;
      this.calculate = item;
      this.$refs['cal-modal'].show();
    },
    showModalUpdate: async function (id) {
      this.create = false;
      this.update = true;
      this.upsertProduct = 'Chỉnh sửa sản phẩm';
      const product = this.items.find((x) => x.id === id);

      const priceFormula = await this.calculateByFormula(product);
      product.productSellingPriceByFormula = priceFormula;

      this.product = Object.assign({}, product);
      this.product.newCalculate = this.product.newCalculate ? 1 : 0;
      this.$refs['my-modal-create'].show();
    },
    showModalCreate() {
      this.resetProduct();
      this.create = true;
      this.update = false;
      this.upsertProduct = 'Tạo mới sản phẩm';
      this.$refs['my-modal-create'].show();
    },
    hideModalCreate() {
      this.$refs['my-modal-create'].hide();
    },
    hideModalCalculate() {
      this.$refs['cal-modal'].hide();
    },
    changeCalculate() {
      let url = process.env.VUE_APP_PRODUCT + '/calculate';
      axios({
        url: url,
        method: 'PUT',
        data: {
          id: this.calculate.productId,
          newCalculate: parseInt(this.selectedCal),
        },
      }).then((response) => {
        if (response.data.status) {
          if (!response.data.data) {
            this.Toast.fire({
              icon: 'info',
              title: response.data.message,
            });
          } else {
            let index = this.items.findIndex(
              (obj) => obj.productId === response.data.data.productId
            );
            this.items[index].newCalculate = response.data.data.newCalculate;

            //  this.items.splice(index, 1, response.data.data)
            this.Toast.fire({
              icon: 'success',
              title: response.data.message,
            });
            this.fetchProduct();
          }
        } else {
          this.Toast.fire({
            icon: 'error',
            title: response.data.message,
          });
        }
      });
    },
    showDatePicker() {
      this.$refs['my-date-picker'].shown();
    },
    resetProduct() {
      this.product = {
        id: null,
        productId: '',
        productName: '',
        productSellingPrice: '',
        barCode: '',
        newCalculate: null,
        approve: null,
        productMbfType: null,
        isSelling: null,
        push: null,
        productSellingPriceByFormula: 0,
      };
    },
    updateProduct() {
      this.$v.product.$touch();
      if (this.$v.product.$anyError) {
        return;
      }

      this.create = false;
      this.update = true;
      this.onBusy = true;

      const product = {
        ...this.product,
        productSellingPrice: unMaskPrice(this.product.productSellingPrice),
      };

      ApiService.put('mbf/products', { data: product })
        .then((response) => {
          if (response.data.status) {
            makeToastSuccess(response.data.message);
            this.fetchProduct();
          } else {
            makeToastFaile(response.data.message);
          }
          this.onBusy = false;
        })
        .catch((error) => {
          this.onBusy = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    createProduct() {
      this.$v.product.$touch();
      if (this.$v.product.$anyError) {
        return;
      }
      this.onBusy = true;
      const product = {
        ...this.product,
        productSellingPrice: unMaskPrice(this.product.productSellingPrice),
      };

      ApiService.post('mbf/products', { data: product })
        .then((response) => {
          if (response.data.status) {
            this.fetchProduct();
            this.resetProduct();
            makeToastSuccess(response.data.message);
          } else {
            makeToastFaile(response.data.message);
          }
          this.onBusy = false;
          this.hideModalCreate();
        })
        .catch((error) => {
          this.onBusy = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    getProductDetail() {
      if (!this.product.productId || !this.product.productId.length) {
        makeToastFaile('Vui lòng nhập id sản phẩm');
        return;
      }

      this.onBusy = true;
      ApiService.query('mbf/products/from-system', {
        params: {
          id: this.product.productId,
        },
      })
        .then((response) => {
          if (response.data.status) {
            const productReturn = response.data.data;
            const productOld = Object.assign({}, this.product);
            this.product = {
              ...productOld,
              productId: parseInt(productReturn.productId),
              ...productReturn,
              newCalculate: null,
              productSellingPriceByFormula: 0,
            };
            makeToastSuccess(response.data.message);
          } else {
            makeToastFaile(response.data.message);
          }
          this.onBusy = false;
        })
        .catch((error) => {
          this.onBusy = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    checkMulNhanh() {
      //bo
      this.onBusy = true;
      let index = null;
      for (const item of this.itemsChild) {
        let url = process.env.VUE_APP_PRODUCT + '/nhanh';
        axios({
          url: url,
          method: 'GET',
          params: {
            id: item.productId,
          },
        })
          .then((result) => {
            if (result.data.status) {
              index = this.itemsChild.findIndex(
                (obj) => obj.productId === result.data.data.productId
              );
              let productR = result.data.data;
              this.itemsChild[index].name = productR.name;
              this.itemsChild[index].productId = productR.productId;
              this.itemsChild[index].approve = productR.approve;
              this.itemsChild[index].barcode = productR.barcode;
              this.itemsChild[index].price = productR.price;
              this.itemsChild[index].valid = 1;
            } else {
              this.itemsChild[index].valid = 2;
            }
          })
          .catch(() => {
            this.itemsChild[index].valid = 2;
          });
      }
      this.onBusy = false;
    },
    createBulkProduct() {
      this.onBusy = true;
      let url = process.env.VUE_APP_PRODUCT + '/bulk';
      axios({
        url: url,
        method: 'POST',
        data: {
          data: this.itemsChild,
          approve: this.approveChild,
        },
      })
        .then((result) => {
          if (result.data.status) {
            this.fetchProduct();
            makeToastSuccess(result.data.message);
            this.resetProduct();
          } else {
            makeToastFaile(result.data.message);
          }
          this.onBusy = false;
          this.hideModalCreate();
        })
        .catch((err) => {
          this.onBusy = false;
          makeToastFaile(err.response.data.message);
        });
    },
    deleteProduct(id) {
      Swal.fire({
        title: "<h1 class='text-danger'>Bạn có chắc muốn xoá</h1>",
        text: 'Xoá sản phẩm khônng thể hoàn tác!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Trở về',
      }).then((result) => {
        if (result.value) {
          ApiService.delete(`mbf/products/${id}`)
            .then((result) => {
              if (result.dismiss != 'cancel') {
                const index = this.items.findIndex(
                  (obj) => obj.id === result.data.data.id
                );
                this.items.splice(index, 1);
                makeToastSuccess(result.data.message);
                this.totalPage--;
              } else {
                makeToastFaile(result.data.message);
              }
              this.onBusy = false;
            })
            .catch((error) => {
              this.onBusy = false;
              if (error.response) {
                makeToastFaile(
                  error.response.data ? error.response.data.message : 'Lỗi'
                );
              } else {
                makeToastFaile(error.message || 'Lỗi');
              }
            });
        }
      });
    },
    pushProduct(id) {
      const settingProductId = id || null;

      if (!settingProductId) {
        makeToastFaile('Yêu cầu id sản phẩm');
        return;
      }

      ApiService.put('mbf/products/push', {
        id: settingProductId,
      })
        .then(() => {
          this.fetchProduct();
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }

          this.$nprogress.done();
        });
    },
    editItem: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-product',
        query: { id: item.id },
      });
      window.open(href, '_blank');
    },
    calculateByFormula: function (item) {
      if (!item.productSellingPrice) return 0;

      if (item.newCalculate === null) return 0;

      return this.getProductFormula({
        price: item.productSellingPrice,
        formula: item.newCalculate,
      });
    },
    getProductFormula: function (params) {
      return ApiService.query('mbf/products/formula', {
        params: params,
      })
        .then((response) => {
          return response.data.status ? response.data.data : 0;
        })
        .catch(() => {
          return 0;
        });
    },
    onchangeFormula: async function () {
      const priceFormula = await this.calculateByFormula({
        ...this.product,
        productSellingPrice: unMaskPrice(this.product.productSellingPrice),
      });

      this.product.productSellingPriceByFormula = priceFormula;
    },
    isValid: function (item) {
      let check = true;

      if (item.productMbfType === null) {
        check = false;
      }
      
      if (item.newCalculate === null) {
        check = false;
      }

      if (!item.productNameVat || !item.productNameVat.trim().length) {
        check = false;
      }
      return check;
    },
  },
};
</script>
